@tailwind base;
@tailwind components;
@tailwind utilities;
@import './typography.scss';

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    // --secondary: 210 40% 96.1%;
    // --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

[data-theme] {
    body {
        background-color: var(--background-primary);
        color: var(--text-primary);
    }
}

input {
    background-color: var(--background-primary);
    color: var(--text-primary);
}

input[type="datetime-local"] {
    border: 0;
    border-bottom: 1px solid var(--delimiter);
    height: 46px;
    outline: 0;
    width: 100%;
}

h1 {
    font-size: 2rem;
    font-weight: 600;
}

h4 {
    font-weight: 500;
}

h5 {
    font-weight: 500;
}

.icon {
    stroke: var(--text-secondary);
}

.phone-input {
    border: 1px solid var(--delimiter);
    border-radius: 5px;
    height: 40px;
    padding: 0 0 0 14px;
    input {
        background-color: transparent;
        height: 40px;
        padding: 0 14px;
    }
}
