.container {
    animation: showContainer 0.2s forwards;
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 49;
}

@keyframes showContainer {
    from {
        background-color: rgba(10,10,10,0);
    }
    to {
        background-color: var(--modal-container-background);
    }
}

.modal {
    --modal-header-height: 56px;
    animation: showModal 0.2s forwards;
    background-color: var(--modal-background);
    box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.13);
    height: 100vh;
    width: 100%;
    .modalHeader {
        align-items: center;
        border-bottom: 1px solid var(--delimiter);
        display: flex;
        flex-direction: row;
        font-weight: 600;
        height: var(--modal-header-height);
        justify-content: space-between;
        padding: 0 1.5rem;
    }
    .modalContent {
        height: calc(100vh - var(--modal-header-height));
        overflow-y: auto;
        padding: 1.5rem;
    }
    &.scrollable {
        .modalContent {
            overflow-y: auto;
        }
    }
}

@keyframes showModal {
    from {
        transform: scale(0.7);
    }
    to {
        transform: scale(1);
    }
}

@media screen and (min-width: 768px) {
    .modal {
        border-radius: 6px;
        height: auto;
        max-width: calc(100% - 62px);
        .modalContent {
            height: auto;
            max-height: calc(100vh - 126px);
            overflow-y: visible;
        }
    }
}
