:root {
    --background-primary: #f7f8fa;
    --background-secondary: #ffffff;
    --background-sidebar: #121212;
    --button-background: #ffffff;
    --button-background-hover: #f7f8fa;
    --font-size-smaller: 1.25rem;
    --header-background: #ffffff;
    --header-border-color: transparent;
    --header-height: 66px;
    --icon: #90959e;
    --delimiter: #e7ecf3;
    --text-primary: rgb(61 66 77);
    --text-secondary: #90959e;
    --orange: #ff6043;
    --card-background: #ffffff;
    --input-background: #ffffff;
    --item-background: #ffffff;
    --item-background-hover: #f4f4f4;
    --item-delimiter-color: #f3f3f3;
    --item-list-border-color: transparent;
    --item-list-border-width: 0px;
    --alert: rgb(247, 75, 75);
    --modal-container-background: rgba(10,10,10,0.15);
    --modal-background: var(--background-secondary);
}

[data-theme="dark"] {
    --background-primary: hsl(0, 0%, 2%);
    --background-secondary: hsl(0, 0%, 10%);
    --background-sidebar: hsl(0, 0%, 10%);
    --button-background: hsl(0, 0%, 10%);
    --button-background-hover: hsl(0, 0%, 14%);
    --header-background: hsl(0, 0%, 8%);
    --header-border-color: #202020;
    --icon: #606366;
    --delimiter: hsl(0, 0%, 14%);
    --text-primary: #ffffff;
    --text-secondary: #90959e;
    --card-background: hsl(0, 0%, 9%);
    --input-background: hsl(0, 0%, 12%);
    --item-background: hsl(0, 0%, 10%);
    --item-background-hover: hsl(0, 0%, 15%);
    --item-delimiter-color: hsl(0, 0%, 15%);
    --item-list-border-color: #202020;
    --item-list-border-width: 1px;
    --modal-container-background: rgba(10,10,10,0.93);
    --modal-background: var(--background-secondary);
}
