.button {
    align-items: center;
    background-color: var(--button-background);
    border: 1px solid var(--delimiter);
    border-radius: 6px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.03);
    cursor: pointer;
    color: var(--text-primary);
    display: flex;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    outline-color: transparent;
    outline-style: solid;
    outline-width: 16px;
    position: relative;
    transition: background-color .2s, outline-color .5s, outline-width .5s;
    &:hover {
        background-color: var(--button-background-hover);
    }
    &:active {
        outline-width: 2px;
        outline-color: var(--orange);
        transition: outline-color .2s, outline-width 0s;
    }
    &.black {
        background-color: #232323;
        box-shadow: none;
        color: #ffffff;
        &:hover {
            background-color: #424242;
        }
        & > svg {
            fill: #ffffff;
        }
    }
    &.orange {
        background-color: var(--text-primary);
        color: var(--background-primary);
        &:hover {
            background-color: var(--text-secondary);
        }
        & > svg {
            fill: var(--background-primary);
        }
    }
    &.red {
        background-color: var(--alert);
        box-shadow: none;
        color: #ffffff;
        &:hover {
            background-color: var(--text-primary);
        }
        & > svg {
            fill: #ffffff;
        }
    }
}
