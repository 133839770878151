.container {
    position: relative;
}

.tooltip {
    animation: showTooltip 0.15s forwards;
    background-color: var(--background-secondary);
    border: 1px solid var(--delimiter);
    border-radius: 8px;
    box-shadow: 0 2px 12px 5px rgba(0,0,0,0.07);
    color: var(--text-primary);
    // font-size: 0.875em;
    position: absolute;
    text-align: start;
    user-select: none;
    white-space: nowrap;
    z-index: 50;
    &.with-padding {
        padding: 0.7rem 1rem;
    }
    a {
        color: var(--text-primary);
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .arrow {
        width: 0;
        height: 0;
        border-inline-start: 10px solid transparent;
        border-inline-end: 10px solid transparent;
        border-bottom: 7px solid var(--delimiter);
        left: 12px;
        position: absolute;
        top: -7px;
        & > div {
            width: 0;
            height: 0;
            border-inline-start: 9px solid transparent;
            border-inline-end: 9px solid transparent;
            border-bottom: 6px solid var(--background-secondary);
            left: -9px;
            position: absolute;
            top: 1.5px;
        }
    }
}

@keyframes showTooltip {
    from {
        opacity: 0;
        transform: translateY(-16px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
