.searchContainer {
    max-width: 600px;
    position: relative;
    width: 100%;
}

input.search {
    background-color: var(--header-background);
    border: 0;
    height: 62px;
    padding: 0 26px;
    outline: none;
    width: 100%;
    &::placeholder {
        color: var(--text-secondary);
        opacity: 1;
    }
}

.loading {
    position: absolute;
    right: 0;
    top: 18px;
}

.results {
    animation: fadeIn 0.4s forwards;
    background-color: var(--item-background);
    border: var(--item-list-border-width) solid var(--item-list-border-color);
    border-radius: 6px;
    box-shadow: 0 2px 12px 5px rgba(0,0,0,0.07);
    margin: 0 0 0 14px;
    max-height: 336px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
}

.result {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 48px;
    padding: 0 14px;
    transition: background-color .2s;
    user-select: none;
    &:hover {
        background-color: var(--item-background-hover);
    }
    &:not(:nth-last-child(1)) {
        border-bottom: 1px solid var(--delimiter);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-16px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
