.notification-container {
    pointer-events: none;
    position: fixed;
    top: 12px;
    width: 100%;
    z-index: 99;
}
.notification {
    animation: show 0.3s forwards, hide 0.2s forwards 6s;
    background-color: var(--background-secondary);
    box-shadow: 0 1px 2px 1px rgba(0,0,0,0.1);
    align-items: center;
    border-radius: 6px;
    display: flex;
    font-weight: 400;
    min-height: 52px;
    margin: 0 auto 7px auto;
    position: relative;
    width: 340px;
    overflow: hidden;
    pointer-events: all;
    transition: all 0.2s;
    .prefix {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        min-width: 52px;
        position: absolute;
        top: 0;
        width: 52px;
        svg { fill: #ffffff; }
    }
    .text {
        padding: 14px 14px 14px 66px;
    }
    &.error {
        .prefix {
            background-color: hsl(0, 86%, 59%);
        }
        .text {
            color: hsl(0, 86%, 59%);
        }
    }
    &.success {
        .prefix {
            background-color: rgb(55, 178, 55);
        }
        .text {
            color: rgb(55, 178, 55);
        }
    }
}

@keyframes show {
    0% {
        opacity: 0;
        transform: scale(0.9) translateY(-32px);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

@keyframes hide {
    0% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
    100% {
        opacity: 0;
        transform: scale(0.9) translateY(-32px);
    }
}
