html {
    font-size: 14px;
}
body {
	color: var(--text-primary);
}
body * {
	font-family: Touche, system-ui, sans-serif;
	box-sizing: border-box;
}

input {
    font-family: Touche, system-ui, sans-serif;
    font-size: 1em;
}

a {
    color: var(--text-primary);
    text-decoration: none;
    transition: color 0.1s;
    &:hover {
        color: var(--orange);
        text-decoration: underline;
    }
}

@font-face {
	font-family: Touche;
	src: local("Touche Light"), local("ToucheLight"),
		url(https://resources.boldsmartlock.com/fonts/touche/files/ToucheW03-Light.woff2) format("woff2");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: Touche;
	src: local("Touche Regular"), local("ToucheRegular"),
		url(https://resources.boldsmartlock.com/fonts/touche/files/ToucheW03-Regular.woff2) format("woff2");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: Touche;
	src: local("Touche Medium"), local("ToucheMedium"),
		url(https://resources.boldsmartlock.com/fonts/touche/files/ToucheW03-Medium.woff2) format("woff2");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: Touche;
	src: local("Touche Semibold"), local("ToucheSemibold"),
		url(https://resources.boldsmartlock.com/fonts/touche/files/ToucheW03-Semibold.woff2) format("woff2");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: Touche;
	src: local("Touche Bold"), local("ToucheBold"),
		url(https://resources.boldsmartlock.com/fonts/touche/files/ToucheW03-Bold.woff2) format("woff2");
	font-weight: 700;
	font-style: normal;
}
