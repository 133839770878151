.subtitle {
    color: var(--text-secondary);
    font-size: var(--font-size-smaller);
    & > span > a {
        color: var(--text-secondary);
    }
}

.userIcon {
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    min-width: 32px;
    position: relative;
    user-select: none;
    width: 32px;
    .initials {
        color: rgba(255, 255, 255, 0.9);
        font-size: 11px;
        font-weight: 600;
        position: relative;
    }
}

.colorIcon {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    svg.gradient {
        border-radius: 50%;
        position: absolute;
    }
}

.iconSvg {
    align-items: center;
    background-color: var(--text-primary);
    border-radius: 50%;
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;
}

.iconDevice,
.iconDeviceGroup,
.iconUserGroup {
    align-items: center;
    background-color: var(--text-secondary);
    background-size: cover;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    .svg {
        fill: #ffffff;
    }
}

.iconName {
    align-items: center;
    display: flex;
    .svg {
        fill: #ffffff;
        position: relative;
    }
}

.iconAdd {
    align-items: center;
    border: 2px dotted var(--icon);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    svg {
        fill: var(--icon) !important;
    }
    &:hover {
        border-color: var(--orange);
        svg {
            fill: var(--orange) !important;
        }
    }
}

.iconMore {
    align-items: center;
    aspect-ratio: 1;
    background-color: var(--background-secondary);
    border: 1px solid var(--delimiter);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    & > svg {
        fill: var(--text-secondary);
    }
}

.detail {
    background-color: var(--item-background);
}

.name {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
