.navigation {
    --item-height: 38px;
    --section-padding: 10px;
    &.mobile {
        background-color: var(--background-sidebar);
        border-radius: 100%;
        border-top-left-radius: 0;
        height: 0;
        left: 0;
        overflow: hidden;
        overflow-y: auto;
        padding-top: 5rem;
        position: fixed;
        top: 0;
        transition: border-radius .15s, width .25s, height .25s;
        width: 0;
        z-index: 99;
        &.visible {
            border-radius: 0;
            display: block;
            height: 100vh;
            width: 100vw;
            .iconClose {
                display: block;
            }
        }
        .iconClose {
            display: none;
            position: fixed;
            right: 2rem;
            top: 2rem;
            z-index: 100;
            & > svg {
                fill: #ffffff;
            }
        }
    }
    .section {
        border-bottom: 1px solid rgba(255, 255, 255, 0.06);
        max-height: calc(var(--item-height) + (var(--section-padding) * 2));
        overflow: hidden;
        padding: var(--section-padding) 0;
        transition: max-height .4s;
        &.unfolded, &.activeRoutes {
            max-height: 300px;
            .item {
                .toggle > svg {
                    transform: rotate(180deg);
                }
            }
        }
        &.activeRoutes {
            .item {
                .toggle {
                    opacity: 0;
                }
            }
        }
        &:not(.unfolded, .activeRoutes) ul {
            opacity: 0;
        }
        .item {
            align-items: center;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            padding: 0 22px;
            align-items: center;
            & > a {
                align-items: center;
                color: #ffffff;
                display: flex;
                flex-grow: 1;
                height: var(--item-height);
                text-decoration: none;
                transition: color .1s;
                &:hover {
                    color: var(--orange);
                }
            }
            .toggle {
                align-items: center;
                border-radius: 50%;
                display: flex;
                height: 32px;
                justify-content: center;
                transition: background-color .2s, opacity .15s;
                width: 32px;
                &:hover {
                    background-color: rgba(255, 255, 255, 0.06);
                    & > svg {
                        fill: #ffffff;
                    }
                }
                & > svg {
                    fill: rgba(255, 255, 255, 0.5);
                    transition: fill .2s, transform .15s;
                }
            }
        }
    }
    ul {
        opacity: 1;
        li {
            & > a {
                --border-width: 3px;
                align-items: center;
                border-inline-start: var(--border-width) solid transparent;
                color: #9d9d9d;
                display: flex;
                text-decoration: none;
                height: var(--item-height);
                padding: 0 calc(22px - var(--border-width));
                transition: background-color .1s, border-color .1s, color .1s;
                & > svg {
                    fill: #9d9d9d;
                    margin-inline-end: 8px;
                    transition: fill 0.1s;
                }
            }
            &:hover, &.active {
                & > a {
                    color: var(--orange);
                    & > svg {
                        fill: var(--orange);
                    }
                }
            }
            &.active {
                & > a {
                    border-color: var(--orange);
                }
            }
        }
    }
}
