.container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: var(--header-height) 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 100vh;
    overflow: hidden;
    & > nav {
        grid-area: 1 / 1 / 3 / 2;
    }
    & > header {
        grid-area: 1 / 1 / 2 / 2;
    }
    & > main {
        grid-area: 2 / 1 / 2 / 3;
    }
}

.sidebar {
    background-color: var(--background-sidebar);
    display: none;
    height: 100vh;
    position: relative;
    .header {
        align-items: center;
        display: flex;
        height: 64px;
        padding: 0 22px;
        .logo {
            height: 30px;
            margin-top: 4px;
        }
    }
}

.main {
    overflow-y: scroll;
    padding: 1.2rem 1.2rem;
    position: relative;
    .center {
        margin: 0 auto;
        max-width: 1100px;
    }
}

header.header {
    align-items: center;
    background-color: var(--header-background);
    border-bottom: 1px solid var(--header-border-color);
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.03);
    display: flex;
    height: var(--header-height);
    justify-content: space-between;
    padding: 0 2rem 0 0;
    position: relative;
    width: 100%;
    z-index: 10;
    .headerLeft {
        align-items: center;
        display: flex;
        width: 100%;
    }
    input.search {
        background-color: var(--header-background);
        border: 0;
        font-size: 1.15em;
        height: 62px;
        max-width: 400px;
        padding: 0 2rem;
        outline: none;
        width: 100%;
        &::placeholder {
            color: var(--text-secondary);
            opacity: 1;
        }
    }
}

.mobileNavButton {
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: center;
    margin: 0 0 0 1.2rem;
    width: 32px;
}

.userProfileContainer {
    position: relative;
}

.userProfile {
    align-items: center;
    cursor: pointer;
    display: flex;
    text-align: end;
    user-select: none;
    white-space: nowrap;
    .accountInfo {
        display: none;
    }
    .org-name {
        color: var(--text-secondary);
    }
    .userIcon {
        margin-inline-start: 8px;
    }
    svg {
        fill: var(--text-secondary);
    }
}

.userMenu {
    animation: showUserMenu 0.25s forwards ease-out;
    background-color: var(--background-primary);
    border: var(--item-list-border-width) solid var(--item-list-border-color);
    border-radius: 8px;
    box-shadow: 0 2rem 6rem rgba(0,0,0,.13);
    position: absolute;
    right: 0;
    text-align: start;
    top: 48px;
    width: 240px;
    z-index: 20;
    .orgs {
        padding: 8px 0;
        .org {
            color: var(--text-primary);
            cursor: pointer;
            padding: 8px 16px;
            text-decoration: none;
            transition: color 0.1s;
            &:hover {
                color: var(--orange);
            }
        }
    }
    .option, option a {
        border-top: 1px solid var(--delimiter);
        color: var(--text-primary);
        display: block;
        padding: 16px;
        text-decoration: none;
        &:hover {
            color: var(--orange);
        }
    }
}

@keyframes showUserMenu {
    from {
        opacity: 0;
        transform: translateY(8px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media screen and (min-width: 768px) {
    .container {
        grid-template-columns: 220px 1fr;
        & > nav {
            grid-area: 1 / 1 / 3 / 2;
        }
        & > header {
            grid-area: 1 / 2 / 2 / 2;
        }
        & > main {
            grid-area: 2 / 2 / 2 / 3;
        }
    }
    .main {
        padding: 2rem 3rem 6rem 3rem;
    }
    .sidebar {
        display: block;
    }
    .userProfile {
        .accountInfo {
            display: block;
        }
    }
    .mobileNavButton {
        display: none;
    }
}
